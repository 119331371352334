<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'promotion histories'"
              :total="dataTotal"
              :fetch-path="'promotionHistory/fetch'"
              :filter-path="'promotionHistory/filter'"
              :export-path="'promotionHistory/export'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({name: 'researcher.administration.promotion-history.see-evaluation', params: {
                          user: field.applicant.id,
                          convo: field.promotion.id
                        }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View evaluation
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="field.hasDocument && field.hasDocument.includes('Upload')"
                        @click="showDocument(field.salary_history)"
                      >
                        <i
                          class="me-50"
                          data-feather="book-open"
                        /> View document
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Year</label>
                  <date-picker
                    v-model="filters['year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Year: ${$event}`, value: $event }, 'year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Levels</label>
                  <input
                    v-model="filters.levels"
                    type="number"
                    min="1"
                    max="5"
                    class="form-control"
                    @change="saveFilter({ customLabel: true, name: `Levels: ${$event.target.value}`, value: `${$event.target.value}` }, 'levels')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Evaluation</label>
                  <v-select
                    v-model="filters.grade"
                    label="name"
                    :options="[
                      { name: 'Unacceptable', value: '1' },
                      { name: 'Inadequate', value: '2' },
                      { name: 'Fair', value: '3' },
                      { name: 'Very good', value: '4' },
                      { name: 'Outstanding', value: '5' },
                    ]"
                    :get-option-key="option => option"
                    @input="saveFilter({ customLabel: true, name: `Evaluation: ${$event ? $event.name : ''}`, value: $event }, 'grade')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Result</label>
                  <v-select
                    v-model="filters.result"
                    label="name"
                    :options="[
                      { name: 'Positive', value: '1' },
                      { name: 'Negative', value: '0' },
                    ]"
                    :get-option-key="option => option"
                    @input="saveFilter({ customLabel: true, name: `Result: ${$event ? $event.name : ''}`, value: $event }, 'result')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >With document</label>
                  <v-select
                    v-model="filters.with_document"
                    label="name"
                    :options="[
                      { name: 'Yes', value: '1' },
                      { name: 'No', value: '0' },
                    ]"
                    :get-option-key="option => option"
                    @input="saveFilter({ customLabel: true, name: `With document: ${$event ? $event.name : ''}`, value: $event }, 'with_document')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Year',
          checked: true,
          order: 2,
        },
        {
          name: 'Levels',
          checked: true,
          order: 3,
        },
        {
          name: 'Evaluation',
          checked: true,
          order: 4,
        },
        {
          name: 'Result',
          checked: true,
          order: 5,
        },
        {
          name: 'Document',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Researcher: 'applicant.name',
        Year: 'promotion.year',
        Levels: 'levels',
        Evaluation: 'evaluation_table',
        Result: 'result',
        Document: 'hasDocument',
      },
      title: 'Promotion history',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'promotionHistory/items',
      dataTotal: 'promotionHistory/itemsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      hosts: 'institutions/institutions',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'promotion_histories')
    await this.$store.dispatch('promotionHistory/filter', this.filters)
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'promotion_histories',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    showDocument(url) {
      window.open(url.files_[0].url, '_blank')
    },
  },
}
</script>
